<template>
	<div>
		<base-page-title :titleText="$t('page.subscriptions.title')" />
		<b-container>
			<b-card class="p-3 mt-4 mb-2">
				<b-card-title>{{ $t("page.subscriptions.details") }}</b-card-title>

				<b-list-group>
					<b-list-group-item>
						<div class="d-flex justify-content-between align-items-center" @click="toggleClientDetails"
							style="cursor: pointer">
							<div>
								<strong>{{ $t("page.subscriptions.client") }}:</strong>
								{{ subscription.clientId.firstName }}
								{{ subscription.clientId.lastName }}
							</div>
							<b-icon :icon="showClientDetails ? 'chevron-up' : 'chevron-down'" font-scale="1.2"></b-icon>
						</div>
						<b-collapse v-model="showClientDetails" class="mt-2">
							<div class="pl-3">
								<p>
									<strong>{{ $t('companyName') }}: </strong>{{ subscription.clientId.companyName }}
								</p>
								<p><strong>{{ $t("email") }}: </strong>{{ subscription.ownerId.email }}</p>
								<p>
									<strong>{{ $t('country') }}: </strong>{{ subscription.clientId.country }}
								</p>
								<p>
									<a href="#" @click.prevent="viewClientProfile">
										<strong>{{ $t('visitProfile') }}</strong>
									</a>
								</p>

							</div>
						</b-collapse>
					</b-list-group-item>

					<b-list-group-item>
						<div class="d-flex justify-content-between align-items-center" @click="toggleServiceDetails"
							style="cursor: pointer">
							<div>
								<strong>{{ $t("page.subscriptions.service") }}:</strong>
								{{ subscription.serviceId.name }}
							</div>
							<b-icon :icon="showServiceDetails ? 'chevron-up' : 'chevron-down'"
								font-scale="1.2"></b-icon>
						</div>

						<b-collapse v-model="showServiceDetails" class="mt-2">
							<div class="pl-3">
								<p>
									<strong>{{ $t("page.subscriptions.numAnalysis") }}:</strong>
									{{ subscription.serviceId.numAnalysis }}
								</p>
								<p>
									<strong>{{ $t("page.subscriptions.activeDays") }}:</strong>
									{{ subscription.serviceId.numDaysActive }}
								</p>
								<p v-if="subscription.serviceId.repeatPlan">
									<strong>{{ $t("page.subscriptions.repeatPlan") }}:</strong>
									{{ subscription.serviceId.repeatPlan }}
									<span v-if="subscription.serviceId.repeats">({{ subscription.serviceId.repeats }}
										times)</span>
								</p>
								<p v-if="subscription.serviceId.repeatAfterDays">
									<strong>{{ $t("page.subscriptions.repeatAfterDays") }}:</strong>
									{{ subscription.serviceId.repeatAfterDays }} days
								</p>
							</div>
						</b-collapse>
					</b-list-group-item>

					<b-list-group-item>
						<strong>{{ $t("page.subscriptions.reccuring") }}:</strong>
						<span :variant="subscription.recurring ? 'success' : 'secondary'">
							{{ subscription.recurring ? "Yes" : "No" }}
						</span>
					</b-list-group-item>

					<b-list-group-item>
						<strong>{{ $t("page.subscriptions.totalAnalysisUsed") }}:</strong>
						{{ subscription.totalAnalysisUsed ? totalAnalysisUsed : 0 }}
					</b-list-group-item>

					<b-list-group-item>
						<strong>{{ $t("page.subscriptions.allowedAnalysis") }}:</strong>
						{{ allowedAnalysis }}
					</b-list-group-item>

					<b-list-group-item>
						<strong>{{ $t("page.subscriptions.analysisUsed") }}: </strong>{{ subscription.analysisUsed ?
							subscription.analysisUsed : 0 }}
					</b-list-group-item>

					<div v-if="subscription.recurring">
						<b-list-group-item>
							<strong>{{ $t("page.subscriptions.resetsLeft") }}</strong>
							{{ subscription.resetsLeft }}
						</b-list-group-item>

						<b-list-group-item>
							<b-form-group label-class="font-weight-bold" label="Next Reset Date">
								<b-form-datepicker v-model="subscription.nextResetDate" disabled></b-form-datepicker>
							</b-form-group>
						</b-list-group-item>
					</div>
					<b-list-group-item>
						<b-form-group label-class="font-weight-bold" label="Expiration Date">
							<b-form-datepicker v-model="subscription.expirationDate" disabled></b-form-datepicker>
						</b-form-group>
					</b-list-group-item>
				</b-list-group>
			</b-card>
		</b-container>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showServiceDetails: false,
			showClientDetails: false,
		};
	},
	computed: {
		subscription() {
			return this.$store.getters["subscriptionPredict/getActiveSubscription"];
		},
		allowedAnalysis() {
			return this.subscription.serviceId.numAnalysis ? this.subscription.serviceId.numAnalysis : this.$t("unlimited")
		}
	},
	methods: {
		toggleServiceDetails() {
			this.showServiceDetails = !this.showServiceDetails;
		},
		toggleClientDetails() {
			this.showClientDetails = !this.showClientDetails;
		},
		viewClientProfile() {
			this.$router.push({
				name: "EditClientAccount",
				params: { id: this.subscription.clientId._id },
			});
		},
	},
};
</script>
